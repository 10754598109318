<template>
  <el-card class="box-card">
    <el-col :span="8">
      <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加预售</el-button>
    </el-col>
    <el-col :span="16" style="text-align: right">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="时间段:">
          <el-date-picker
              v-model="query_time"
              size="small"
              type="datetimerange"
              @change="getTimeSection"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="right">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品名称:">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入商品名称"
              size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24">
      <el-radio-group v-model="queryForm.type" @change="buttonTab">
        <el-radio-button type="button"
                         v-for="item in button_state"
                         :key="item.id"
                         :label="item.id"
        >
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </el-col>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column align="right" label="" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image v-if="row.sku_info.imgUrl" :src="row.sku_info.imgUrl" class="commodity_pic"/>
          <el-image v-else src="#" class="commodity_pic"/>
        </template>
      </el-table-column>
      <el-table-column align="left" label="商品" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.sku_info.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="sku_info.price"
          label="商品价格">
      </el-table-column>
      <el-table-column
          align="center"
          prop="inventory"
          label="预售总库存">
      </el-table-column>
      <el-table-column
          align="center"
          prop="sales_volume"
          label="销量">
      </el-table-column>
      <el-table-column align="center" label="定金支付时间" show-overflow-tooltip>
        <template #default="{ row }">
          <div>开始时间{{ row.presell.begin_time | formatDate }}</div>
          <div>结束时间{{ row.presell.end_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="尾款支付时间" show-overflow-tooltip>
        <template #default="{ row }">
          <div>开始时间{{ row.presell.pay_begin_time | formatDate }}</div>
          <div>结束时间{{ row.presell.pay_end_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="state"
          label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiDeleteBookingList, apiGetBookingGoodsList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      query_time: [],
      activeName: '1',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        type: 0,
        begin_time: '',
        end_time: '',
      },
      current: 0,
      button_state: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未开始'
        },
        {
          id: 2,
          name: '活动进行中'
        },
        {
          id: 3,
          name: '活动已结束'
        },
        {
          id: 4,
          name: '已关闭'
        },
      ],
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    //获取时间段
    getTimeSection(event) {
      if (event != null) {
        this.queryForm.begin_time = event[0]
        this.queryForm.end_time = event[1]
      } else {
        this.queryForm.begin_time = ''
        this.queryForm.end_time = ''
      }
    },
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.type = 1
      } else if (num == 2) {
        this.queryForm.type = 2
      } else if (num == 3) {
        this.queryForm.type = 3
      } else if (num == 4) {
        this.queryForm.type = 4
      } else {
        this.queryForm.type = 0
      }
      this.fetchData()
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'preSale/revise',
          query: {
            id: row.presell_id,
          },
        })
      } else {
        this.$router.push({
          path: 'preSale/revise',
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要操作当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteBookingList({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetBookingGoodsList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '未开始'
          : row.state == '1' ? '活动进行中'
              : row.state == '2' ? '活动已结束'
                  : row.state == '2' ? '已关闭'
                      : '暂无'
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}
</style>
