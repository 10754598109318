var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"box-card"},[_c('el-col',{attrs:{"span":8}},[_c('el-button',{attrs:{"icon":"el-icon-plus","type":"warning","size":"small"},on:{"click":_vm.handleEdit}},[_vm._v("添加预售")])],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":16}},[_c('el-form',{attrs:{"inline":true,"model":_vm.queryForm},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"时间段:"}},[_c('el-date-picker',{attrs:{"size":"small","type":"datetimerange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":"yyyy-MM-dd HH:mm:ss","align":"right"},on:{"change":_vm.getTimeSection},model:{value:(_vm.query_time),callback:function ($$v) {_vm.query_time=$$v},expression:"query_time"}})],1),_c('el-form-item',{attrs:{"label":"商品名称:"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入商品名称","size":"small"},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryForm.name"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"icon":"el-icon-search","type":"primary","size":"small"},on:{"click":_vm.queryData}},[_vm._v(" 查询 ")])],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-radio-group',{on:{"change":_vm.buttonTab},model:{value:(_vm.queryForm.type),callback:function ($$v) {_vm.$set(_vm.queryForm, "type", $$v)},expression:"queryForm.type"}},_vm._l((_vm.button_state),function(item){return _c('el-radio-button',{key:item.id,attrs:{"type":"button","label":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('el-table',{attrs:{"data":_vm.list},on:{"selection-change":_vm.setSelectRows}},[_c('el-table-column',{attrs:{"align":"right","label":"","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.sku_info.imgUrl)?_c('el-image',{staticClass:"commodity_pic",attrs:{"src":row.sku_info.imgUrl}}):_c('el-image',{staticClass:"commodity_pic",attrs:{"src":"#"}})]}}])}),_c('el-table-column',{attrs:{"align":"left","label":"商品","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.sku_info.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"sku_info.price","label":"商品价格"}}),_c('el-table-column',{attrs:{"align":"center","prop":"inventory","label":"预售总库存"}}),_c('el-table-column',{attrs:{"align":"center","prop":"sales_volume","label":"销量"}}),_c('el-table-column',{attrs:{"align":"center","label":"定金支付时间","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v("开始时间"+_vm._s(_vm._f("formatDate")(row.presell.begin_time)))]),_c('div',[_vm._v("结束时间"+_vm._s(_vm._f("formatDate")(row.presell.end_time)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"尾款支付时间","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v("开始时间"+_vm._s(_vm._f("formatDate")(row.presell.pay_begin_time)))]),_c('div',[_vm._v("结束时间"+_vm._s(_vm._f("formatDate")(row.presell.pay_end_time)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"state","label":"状态"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])})],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.queryForm.page,"layout":_vm.layout,"align":"center","page-size":_vm.queryForm.pageSize,"total":_vm.count},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}}),_c('Edit',{ref:"Edit"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }